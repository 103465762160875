@font-face {
  font-family: "Human-beomseok";
  src: url("./assets/font/Human-beomseok.ttf");
}

@font-face {
  font-family: 'S-CoreDream-3Light';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-size: 18px;
  margin: 0;
  padding: 5vh 0 0 0;
  font-family: "S-CoreDream-3Light", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  // 드래그 방지
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


// 데스크탑
@media screen and (min-width: 1024px){
	body {
    min-width: 1024px
  }
}

// 테블릿
@media screen and (max-width: 1023px){
	body {
    max-width: 1023px
  }
}

// 모바일
@media screen and (max-width: 767px){
	body {
    max-width: 767px;
    min-width: 412px
  }
}