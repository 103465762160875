@font-face {
  font-family: 'S-CoreDream-3Light';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.introduceWrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 5rem 2rem 0 2rem;

  .character {
    position: relative;
    
    
    .tooltip .tooltiptext {
      visibility: hidden;
      position: fixed;
      width: 100px;
      font-size: 0.9rem;
      background-color: #2b3b307a;
      color: #f5efe6;
      text-align: center;
      border-radius: 6px;
      margin: 0.5rem;
      z-index: 1;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }
  
  

  .box {
    margin-left: 2rem;
    .introduceText{
      margin-bottom: 1rem;
      h1{
        margin: 0;
      }
    }

    .introduceButton{
      button{
        margin-right: 0.5rem;
        font-family: "S-CoreDream-3Light";
        font-weight: bold;
        transition: 0.25s;
        border: none;
        padding: 5px 14px;
        border-radius: 10px;
        background-color: #0D0D0D;
        color: #D9D8D2;
        
        a{
          text-decoration: none;
          color: #D9D8D2;
        }
      }

      button:hover {
        background-color: #D9D8D2;
        color: #0D0D0D;

        a{
          text-decoration: none;
          color: #0D0D0D;
        }
      }
      
    }
  }
}

@media screen and (min-width: 300px) {
  .introduceWrapper{
    margin: 5rem 0rem 2rem 0rem;
  }
}

// 모바일
@media screen and (max-width: 767px){
  .introduceWrapper{
    margin: 5rem 0rem 2rem 0rem;
  }
}