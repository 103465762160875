
hr {
  border-color: #73737258;
}

section {
  padding: 10% 1rem 0 1rem;
}


// Skills
.skills {
  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 3rem 0;

    .box {
      max-width: 30rem;
      text-align: center;
    }
  }
}


// Projects
.projects {
  
  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    .projectWrapper {
      border: 1px solid #73737258;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      transition: transform .3s;
      background-color: white;
      margin-bottom: 5px;
      align-items: center;

      .imgBox {
        height: 8rem;
        width: 17rem;
        margin: 0.5rem;
        align-content: center;
        text-align: center;
        overflow:hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down
        }
      }

      .textBox {
        height: auto;
        width: 30rem;
        margin: 1rem;

        h4 {
          margin: 0;
        }

        p {
          margin: 1rem 0;
        }
      }
    }

    .projectWrapper:hover {
      transform: scale(1.2);
    }

    .modalBox {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      backdrop-filter: blur(5px);
      animation: fadeIn 0.5s ease-out forwards;
      z-index: 10;


      .modal {
        border: 1px solid #73737258;
        border-radius: 8px;
        background-color: #f8f8f8;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
  
        .modealHeader {
          text-align: right;
          padding: 5px 5px;

          svg {
            fill: #737372;
          }
        }
  
        .modalContent {
          .imgBox {
            height: 20rem;
            margin: 0 2rem 1rem 2rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
              background-color: #f8f8f8;
            }
          }

          hr {
            width: 95%;
          }

          .textBox {
            padding: 0 2rem 2rem 2rem;
            div {
              .title {
                font-weight: bold;
                font-size: 1.3rem;
              }
              .date {
                font-size: 0.9rem;
              }
            }

            .description {
              margin-top: 1rem;
              margin-bottom: 1rem;
              font-size: 1rem;
            }

            .subtitle {
              font-weight: bold;
              font-size: 1rem;
            }
            span {
              font-size: 1rem;
            }

            p {
              margin: 0;
            }

            .link {
              margin-top: 16px;

              button{
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                font-family: "S-CoreDream-3Light";
                font-weight: bold;
                transition: 0.25s;
                border: none;
                padding: 5px 14px;
                border-radius: 10px;
                background-color: #0D0D0D;
                color: #D9D8D2;
                
                a{
                  text-decoration: none;
                  color: #D9D8D2;
                }
              }

              button:hover {
                background-color: #D9D8D2;
                color: #0D0D0D;
        
                a{
                  text-decoration: none;
                  color: #0D0D0D;
                }
              }
            }
          }
        }
      }
    }

  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Footer
footer {
  height: 5rem;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  
  div {
    width: 60%;
    border-top: 1px solid #73737258;
    color: #737372;
    
  }
}


// 데스크탑
@media screen and (min-width: 1024px){

}

// 테블릿
@media screen and (max-width: 1023px){

}

// 모바일
@media screen and (max-width: 767px){
  section {
    padding: 10% 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  article {
    margin: 0;
    width: 80vw;
  }

  // Projects
  .projectWrapper {
    width: 20rem;

    .textBox {

      h4 {
        margin: 0;
        font-size: 1rem;
      }

      p {
        margin: 1rem 0;
      }
    }
  }

  .modalBox {
    .modal {
      width: 24rem;
    }
  }

}