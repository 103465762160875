
header {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 15rem;
    justify-content: space-evenly;
  }
}

.active {
  text-decoration: underline;
}

.headerBackground {
  background-color: rgb(255, 255, 255);
}


@media screen and (max-width: 767px){
	header {
    
  }
}